import { twMerge } from 'tailwind-merge';

export type SvgProps = {
  size?: 'sm' | 'md' | 'lg',
  children?: React.ReactNode,
  viewBox?: string,
  className?: string,
} & React.ComponentProps<'svg'>;

const Svg = ({
  size = 'md', className, children, ...rest
}: SvgProps) => {
  const getNumericalSize = () => {
    if (size === 'sm') return 16;
    if (size === 'md') return 24;
    if (size === 'lg') return 32;
    return size;
  };

  const numericalSize = getNumericalSize();

  return (
    <div className="w-fit h-fit">
      <svg
        className={twMerge('fill-blue-100 stroke-blue-100', className)}
        xmlns="http://www.w3.org/2000/svg"
        width={numericalSize}
        height={numericalSize}
        {...rest}
      >
        {children}
      </svg>
    </div>
  );
};

export default Svg;
