import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Tick = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 25" className={twMerge('stroke-none', className)} {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.2929 6.74956C19.6834 6.35903 20.3166 6.35903 20.7071 6.74956C21.0676 7.11004 21.0953 7.67727 20.7903 8.06956L20.7071 8.16377L10.7071 18.1638C10.3466 18.5243 9.77939 18.552 9.3871 18.247L9.29289 18.1638L4.29289 13.1638C3.90237 12.7732 3.90237 12.1401 4.29289 11.7496C4.65338 11.3891 5.22061 11.3613 5.6129 11.6664L5.70711 11.7496L10 16.0417L19.2929 6.74956Z" />
  </Svg>
);

export default Tick;
