import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Cross = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 20 20" className={twMerge('stroke-none', className)} {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 1.66699C5.39771 1.66699 1.66675 5.39795 1.66675 10.0003C1.66675 14.6027 5.39771 18.3337 10.0001 18.3337C14.6025 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6025 1.66699 10.0001 1.66699ZM10.0001 3.33366C13.682 3.33366 16.6667 6.31843 16.6667 10.0003C16.6667 13.6822 13.682 16.667 10.0001 16.667C6.31818 16.667 3.33341 13.6822 3.33341 10.0003C3.33341 6.31843 6.31818 3.33366 10.0001 3.33366ZM8.92267 7.7444L8.84416 7.67508L8.75958 7.61731C8.43967 7.42667 8.01953 7.46903 7.74416 7.7444C7.41872 8.06984 7.41872 8.59748 7.74416 8.92291L8.82091 10.0003L7.74416 11.0777L7.67484 11.1562C7.42065 11.4832 7.44376 11.9558 7.74416 12.2562C8.0696 12.5817 8.59723 12.5817 8.92267 12.2562L10.0001 11.1795L11.0775 12.2562L11.156 12.3256C11.4829 12.5798 11.9556 12.5567 12.256 12.2562C12.5814 11.9308 12.5814 11.4032 12.256 11.0777L11.1792 10.0003L12.256 8.92291L12.3253 8.84441C12.5795 8.5175 12.5564 8.04481 12.256 7.7444C11.9306 7.41897 11.4029 7.41897 11.0775 7.7444L10.0001 8.82116L8.92267 7.7444Z" />
  </Svg>
);

export default Cross;
